import React, { useState } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQs = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid item xs={12} pb={2}>
      {[
        {
            question: "Eye Movement Desensitization and Reprocessing Therapy (EMDR)",
             answer: (
              <>
                EMDR is a structured therapy that encourages the patient to focus briefly on the trauma memory while simultaneously experiencing bilateral stimulation (typically eye movements), which is associated with a reduction in the vividness and emotion associated with the trauma memories. Eye Movement Desensitization and Reprocessing (EMDR) therapy is an extensively researched, effective psychotherapy method proven to help people recover from trauma and PTSD symptoms. Ongoing research supports positive clinical outcomes, showing EMDR therapy as a helpful treatment for disorders such as anxiety, depression, OCD, chronic pain, addictions, and other distressing life experiences (Maxfield, 2019).
              <br />
              To read more, click{" "}
              <a href="https://www.emdria.org/about-emdr-therapy/" target="_blank" rel="noopener noreferrer">
                here
                </a>.
                 </>
                )
              },
              {
  question: "Trauma-Focused Cognitive Behavioral Therapy (TF-CBT)",
  answer: (
    <>
      <strong>Trauma-Focused Cognitive Behavioral Therapy (TF-CBT)</strong> is an evidence-based practice model designed to support children and adolescents who have experienced traumatic or stressful situations.
      <br /><br />
      This model utilizes age-appropriate psychoeducation regarding anxiety and trauma responses, emotional labeling and expression, and cognitive coping prior to guiding children and adolescents to share their stories in their own words. 
      <br /><br />
      A primary focus is placed on healthy and age-appropriate perspective throughout the process, as well as emphasizing present and future safety and resilience. Appropriate caregivers play consistent roles throughout the course of this structured model with the guidance of the trained provider.
    </>
  )
},

          {
  question: "Grief Therapy (Bereavement therapy)",
  answer: (
    <>
      Grief counseling, also known as bereavement therapy, is a form of therapy intended to help you cope with loss, such as the death of a partner, family member, friend, colleague, or animal companion.
      <br /><br />
      <strong>Grief Therapy:</strong> This form of therapy can help address behavioral and physical problems that you may face in the aftermath of a loss. It can also help you if you cannot separate yourself emotionally from the person who passed away.
      <br /><br />
      <strong>Complicated Grief Therapy:</strong> In complicated grief, deep grief takes hold of you and doesn’t let go. It can result in troubling thoughts, dysfunctional behaviors, and difficulty regulating emotions, making it harder for you to adapt to life without your loved one.
      <br /><br />
      <strong>Traumatic Grief Therapy:</strong> You may experience traumatic grief if you lost a loved one suddenly or if you witnessed their death. Traumatic grief therapy can help you deal with the intensity of your grief, reduce the symptoms of trauma, and equip you with coping skills.
      <br /><br />
      <strong>Disenfranchised Grief Therapy:</strong> Disenfranchised grief, also known as hidden grief or sorrow, refers to any grief that goes unacknowledged or unvalidated by social norms. This kind of grief is often minimized or not understood by others, which makes it particularly hard to process and work through. Therapy can be a useful tool that can help provide the validation and recognition of your grief that you need. It can also help you explore and process your feelings toward the object of your grief.
      <br /><br />
      To read more, click{" "}
      <a href="https://www.verywellmind.com/" target="_blank" rel="noopener noreferrer">
        here
      </a>.
    </>
  )
},
{
  question: "Somatic Therapy",
  answer: (
    <>
      <strong>Somatic Experiencing (SE)</strong> is a form of bodywork therapy that accesses and builds upon our inner resilience, empowering the body to complete the natural cycle of its self-protective responses to resolve traumas that are stored in body memory.
      <br /><br />
      Trauma and harm are interruptions that cause us to freeze and recoil to the self for protection. Healing involves motion, flow, and opening oneself to connections in a context of trust.
    </>
  )
},

{
  question: "Accelerated Resolution Therapy (ART)",
  answer: (
    <>
      <strong>Accelerated Resolution Therapy (ART)</strong> works to reprogram the way distressing memories and images are stored so they no longer trigger strong emotional or physical reactions.
      <br /><br />
      Guided by a therapist, clients replace negative images with positive ones of their choosing using eye movements similar to those in the REM sleep cycle, which changes the physiological response to memory.
      <br /><br />
      ART is evidence-based, allowing individuals to recall the facts of an incident without the associated physical and emotional feelings. This distancing from emotional responses provides the opportunity to look at situations objectively.
      <br /><br />
      The therapy is time-limited and does not require sharing the details of the trauma or event. It addresses symptoms of trauma as well as various other mental health difficulties, including pain management, making it a client-centered approach.
    </>
  )
},
          {
  question: "Exposure Response Prevention (ERP)",
  answer: (
    <>
      <strong>Exposure Response Prevention (ERP)</strong> is the most important type of Cognitive Behavioral Therapy (CBT) for OCD. The exposure component of ERP refers to practicing confronting the unwanted thoughts, images, objects, and situations that make you anxious and/or provoke your obsessions.
      <br /><br />
      The response prevention part of ERP refers to making a choice not to do a compulsive behavior once the anxiety or obsessions have been triggered. All this is done under the guidance of a therapist at the beginning, though you will eventually learn to do your own ERP exercises to manage your symptoms. Over time, the treatment will retrain your brain to no longer see the object of obsession as a threat.
      <br /><br />
      This strategy of purposefully exposing yourself to things that make you anxious may not sound quite right to you. Maybe it sounds difficult. If you have OCD, you have probably tried to confront your obsessions and anxiety only to see that you become very anxious and fearful. With ERP, the difference is that a trained clinician is working with you to develop a plan for exposure. Then, that therapist coaches you through confronting the situation, leaning into the feelings it provokes, sticking with it, and resisting the urge to engage in compulsive behavior.
      <br /><br />
      To read more, visit the International Obsessive Compulsive Disorder Foundation website:{" "}
      <a href="https://iocdf.org/about-ocd/treatment/erp/" target="_blank" rel="noopener noreferrer">
        Exposure Response Prevention (ERP)
      </a>.
    </>
  )
},
{
  question: "Imagery Rescripting and Reprocessing Therapy (IRRT)",
  answer: (
    <>
      <strong>Imagery Rescripting and Reprocessing Therapy (IRRT)</strong> is an imagery-focused treatment designed to help with symptoms of PTSD/PTSI, particularly in survivors of childhood sexual abuse who struggle with feelings of powerlessness, unloveability, and abandonment.
      <br /><br />
      This therapy involves revisiting the traumatic images, thoughts, and effects to identify and challenge them. Through this process, the visions seen from the “traumatized child’s” perspective can be reprocessed and modified through the eyes of one’s “adult self.”
    </>
  )
},
{
  question: "Cognitive Processing Therapy (CPT)",
  answer: (
    <>
      <strong>Cognitive Processing Therapy (CPT)</strong> is a specific type of cognitive behavioral therapy that has been effective in reducing symptoms of PTSD that have developed after experiencing a variety of traumatic events, including child abuse, PTSD, assault, and others.
      <br /><br />
      To learn more, visit the American Psychological Association (APA) website:{" "}
      <a href="https://www.apa.org/ptsd-guideline/treatments/cognitive-processing-therapy" target="_blank" rel="noopener noreferrer">
        Cognitive Processing Therapy (CPT)
      </a>.
    </>
  )
},
          {
  question: "Motivational Interviewing (MI)",
  answer: (
    <>
      <strong>Motivational Interviewing (MI)</strong> is designed to empower people to change by drawing out their own meaning, importance, and capacity for change. MI is based on a respectful and curious way of being with people that facilitates the natural process of change and honors client autonomy.
      <br /><br />
      To learn more, visit the Motivational Interviewing Network of Trainers (MINT) website:{" "}
      <a href="https://motivationalinterviewing.org/understanding-motivational-interviewing" target="_blank" rel="noopener noreferrer">
        Understanding Motivational Interviewing
      </a>.
    </>
  )
},
/* {
  question: "Brain Spotting",
  answer: (
    <>
      <strong>Brain Spotting</strong> is based on the principle that where we look affects how we feel. It locates points in the client’s visual field that help to access unprocessed trauma in the subcortical brain.
      <br /><br />
      Clients typically fall into two categories:
      <br />
      1. Those who are seeking therapy for the first time.
      <br />
      2. People who have been in therapy before and are seeking a new therapist or additional therapy techniques.
      <br /><br />
      With focus and precision, brain spotting allows us to find, through eye positions, the brain spots where you are holding trauma, anxiety, depression, or relational problems. This technique helps the brain process these issues from the inside-out and from the bottom up.
      <br /><br />
      <strong>Brain Spotting Treats:</strong>
      <br />
      - Anxiety
      <br />
      - Attachment issues
      <br />
      - Substance use
      <br />
      - Posttraumatic stress disorder (PTSD)
      <br />
      - Chronic pain
      <br />
      - Major depressive disorder
      <br /><br />
      <strong>Benefits of Brain Spotting:</strong>
      <br />
      - Reduction in pain
      <br />
      - Memories become less painful
      <br />
      - Negative thought patterns are reduced
      <br />
      - Better sleep
      <br />
      - Increased energy
    </>
  )
}, */

{
  question: "Cold Exposure Therapy",
  answer: (
    <>
      <strong>Cold Exposure Therapy</strong> offers numerous benefits that affect both the body and mind. These include:
      <br /><br />
      - Causes release of epinephrine (adrenaline) and norepinephrine, which increases alertness, energy, and focus.
      <br />
      - Increases prolonged release of dopamine, a powerful molecule capable of elevating mood, enhancing focus, attention, and goal-oriented behavior.
      <br />
      - Increases metabolism and brown fat, and increases adiponectin, a fat-burning protein.
      <br />
      - Enhances resilience and increases blood circulation.
      <br />
      - Improves sleep and immune function.
      <br />
      - May support longevity and physical recovery.
      <br />
      - Strengthens the nervous system, helping regulate mood.
      <br />
      - Helps regulate blood sugar and reduces inflammation.
      <br />
      - Combats oxidative stress and reduces pain.
    </>
  )
},

          {
  question: "Reiki",
  answer: (
    <>
      <strong>Reiki</strong> is a complementary therapy related to energy healing. Proponents say it works through the transfer of universal energy from the practitioner’s palms to the client. Reiki is an energy healing technique that promotes relaxation, reduces stress, and alleviates anxiety through gentle touch. Reiki practitioners use their hands to deliver energy to your body, improving the flow and balance of your energy to support healing.
      <br /><br />
      Mikao Usui developed Reiki in the early 1900s, deriving the term from the Japanese words <em>rei</em>, meaning “universal,” and <em>ki</em>, which refers to the vital life force energy that flows through all living things. Now, Reiki is used all over the world, including in hospitals and hospices, to complement other forms of health treatments. “Reiki aids in healing by helping people become energetically balanced — physically, emotionally, mentally, and spiritually,” Bodner says.
      <br /><br />
      Watch a Reiki video to learn more:{" "}
      <a href="https://www.youtube.com/watch?v=2S5lo-yIF7Y" target="_blank" rel="noopener noreferrer">
        Reiki Video
      </a>.
    </>
  )
},
{
  question: "Trauma-Informed Yoga",
  answer: (
    <>
      <strong>Trauma-Informed Yoga</strong> supports the ability to improve the mind-body connection, which involves having awareness of physical functioning and presence while also maintaining a sense of self in the moment.
      <br /><br />
      Many factors can interrupt this awareness and connection, including traumatic experiences, injury or chronic pain, learned behavior, and other influences. Trauma-Informed Yoga is designed to support:
      <br /><br />
      - The creation of a stronger, more self-aware relationship between mind and body.
      <br />
      - Allowing individuals to decide what shapes/movements allow them to feel safe, aware, and present in their body.
      <br />
      - Supporting individuals' sense of control and autonomy.
    </>
  )
},

          {
  question: "EcoTherapy",
  answer: (
    <>
      <strong>EcoTherapy</strong> is a type of therapeutic treatment that involves doing outdoor activities in nature. There isn't one single definition of ecotherapy, but it's often used to describe a regular, structured activity that:
      <br /><br />
      - Is led by trained professionals (sometimes therapists), who are there to support you.
      
      <br />
      - Takes place in nature or on or near to water.
      <br />
      - Is related to exploring and appreciating the natural world.
      <br /><br />
      Ecotherapy can take place in both the countryside and in towns and cities. Settings for it include parks, gardens, farms, and woodlands. It involves varying amounts of physical activity, depending on the type of program. It can include activities that focus on:
      <br /><br />
      - Working in nature, such as a conservation project, gardening, or farming.
      <br />
      - Experiencing nature, such as enjoying the views on a walk or cycling through woodlands.
      <br /><br />
      To learn more, visit the Mind website:{" "}
      <a href="https://www.mind.org.uk" target="_blank" rel="noopener noreferrer">
        EcoTherapy Information
      </a>.
    </>
  )
},

{
  question: "Forest Bathing",
  answer: (
    <>
      <strong>Forest Bathing</strong>, also known as <em>shinrin-yoku</em> in Japanese, means “taking in the forest atmosphere.” The practice was developed in Japan to offer an eco-antidote to tech-boom burnout and to inspire residents to reconnect with and protect the country’s forests. 
      <br /><br />
      Forest bathing involves immersing oneself in nature to improve mental and physical health by being part of the natural environment and consciously connecting with what’s around you. It's a low-impact activity that usually involves walking or sitting in nature, and it does not require actual bathing.
      <br /><br />
      Watch a video about Forest Bathing:{" "}
      <a href="https://www.youtube.com/watch?v=gxULUoa5sRA" target="_blank" rel="noopener noreferrer">
        Forest Bathing Video
      </a>.
    </>
  )
}
      ].map((faq, index) => (
        <Accordion
          key={index}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};

export default FAQs;